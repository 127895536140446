import React from "react";
import { Card, Row, Col } from "antd";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { UpgradeIcon } from "../Icons";
import { DARK_MODE } from "constants/ThemeConstant";
import { handleDocsLinkClick } from "../TooltipDrawer/TooltipDrawer";

const defaultBackgroundStyle = {
    backgroundImage: "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
};

const DR_DOCS_LINK = "https://docs.controlmonkey.io/main-concepts/disaster-recovery/infrastructure-daily-backup";

// Default in case no component was set in routes
const DefaultCapabilityComponent = ({ feature = "This feature", backgroundImageURL, videoEmbed, docsLink = '' }) => {
    const mainFeature = (
        <Row justify="center" align="middle" className="w-100 h-100">
            <Col span={12}>
                <Card className="text-center mt-2">
                    <h2>Upgrade Your subscription to access this feature</h2>
                    <p>{feature} is not enabled in your current plan</p>
                    <div className="my-2">
                        <UpgradeIcon width={64} height={64} />
                    </div>
                    <p>please contact our <a href="mailto:support@controlmonkey.io">support</a> team for more info</p>
                    {docsLink && (
                        <p>
                            To learn more about this feature, please refer to our{" "}
                            <a onClick={(e) => handleDocsLinkClick(e, docsLink)} href={docsLink} target="_blank" rel="noopener noreferrer">
                                docs
                            </a>.
                        </p>
                    )}
                    {videoEmbed}
                </Card>
            </Col>
        </Row>
    );

    let backgroundStyle = {
        ...defaultBackgroundStyle,
    };

    if (backgroundImageURL) {
        backgroundStyle.backgroundImage = `url(${backgroundImageURL})`;
    }

    return (
        <div style={backgroundStyle}>
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(2px) brightness(90%)",
                }}
            />
            {mainFeature}
        </div>
    );
};

// Disaster recovery
export const DisasterRecoveryCapabilityComponent = () => {
    const { currentTheme } = useThemeSwitcher();

    let backgroundImageURL = "/img/others/upgrade_dr_white_bg.jpg";
    if (currentTheme === DARK_MODE) {
        backgroundImageURL = "/img/others/upgrade_dr_dark_bg.jpg"
    }

    const videoEmbed = (
        <iframe style={{border: '1px solid #4d5b75'}} width="560" height="315" src="https://www.youtube.com/embed/keH4M6Rryyc?si=ioLMmPxJaBOfJN1i" title="ControlMonkey Disaster Recovery" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    )

    return <DefaultCapabilityComponent feature="Disaster Recovery" backgroundImageURL={backgroundImageURL} videoEmbed={videoEmbed} docsLink={DR_DOCS_LINK} />;
};

export default DefaultCapabilityComponent;
