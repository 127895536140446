import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
      <Suspense fallback={<Loading cover="page" />}>
        <Switch>
          <Route path={`${AUTH_PREFIX_PATH}/login/sso`} component={lazy(() => import(`./authentication/sso-login-request`))} />
          <Route path={`${AUTH_PREFIX_PATH}/ssoBadLogin`} component={lazy(() => import(`./authentication/sso-login-failed`))} />
          <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
          <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />
          <Route path={`${AUTH_PREFIX_PATH}/ssoLogin`} component={lazy(() => import(`./authentication/sso-login`))} />
          <Route path={`${AUTH_PREFIX_PATH}/ssoChooseLogin`} component={lazy(() => import(`./authentication/sso-choose-login`))} />
          <Route path={`${AUTH_PREFIX_PATH}/awsMarketplaceRegister`} component={lazy(() => import(`./authentication/aws-marketplace-register`))} />
          <Route path={`${AUTH_PREFIX_PATH}/postAwsMarketplaceRegistration`} component={lazy(() => import(`./authentication/aws-marketplace-register/PostAwsMpRegistration.js`))} />
          <Route path={`${AUTH_PREFIX_PATH}/signup`} component={lazy(() => import(`./authentication/signup-organization`))} />
          
          {/* invite user */}
          <Route path={`${AUTH_PREFIX_PATH}/acceptInvite/:invitationToken`} component={lazy(() => import(`./authentication/invite`))} />
          <Route path={`${AUTH_PREFIX_PATH}/acceptInviteExistingUser/:invitationToken`} component={lazy(() => import(`./authentication/invite-existing-user`))} />
          {/* recover password to create reset link with token */}
          <Route path={`${AUTH_PREFIX_PATH}/recoverPassword`} component={lazy(() => import(`./authentication/recover-password`))} />
          {/* reset password link with token */}
          <Route path={`${AUTH_PREFIX_PATH}/resetPassword/:resetToken`} component={lazy(() => import(`./authentication/reset-password`))} />
          <Route path={`${AUTH_PREFIX_PATH}/confirmMfa/:confirmationToken`} component={lazy(() => import(`./authentication/confirm-mfa`))} />
          {/* <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} /> */}
          <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
        </Switch>
      </Suspense>
  )
}

export default AppViews;

