import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { AuthDataProvider } from "context/AuthDataContext";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, ENTRY_ROUTE } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import localStorageHelper from "helpers/localStorageHelper";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  useEffect(() => {
    if (!isAuthenticated) {
      // token was revoked or user is not logged in - hard loading /auth/login to reset state
      window.location.href = ENTRY_ROUTE;
    }

  }, [isAuthenticated]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ENTRY_ROUTE,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Pages = (props) => {
  const { locale, location, direction } = props;
  const token = localStorageHelper.getToken();
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>
            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
              <AuthDataProvider>
                <AppLayout direction={direction} location={location} />
              </AuthDataProvider>
            </RouteInterceptor>
          </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Pages));