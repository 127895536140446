import { useContext } from "react";
import moment from "moment";
import { Row, Col } from "antd";
import AuthDataContext from "context/AuthDataContext";
import AppDataContext from "context/AppDataContext";
import useAppSettings from "hooks/useAppSettings";
import { APP_SETTINGS_KEYS, ROLE } from "constants/AppConstant";
import { USERS_PATH } from "configs/AppConfig";

const isCurrentDateAfterRemindMeLaterDate = (remindMeLaterDateStr) => {
    const remindDate = moment(remindMeLaterDateStr);
    const currentDate = moment();
    return currentDate.isAfter(remindDate);
};

const SignInSettingsBanner = () => {
    const { orgUserRole } = useContext(AuthDataContext);
    const { signInSettings, users = [] } = useContext(AppDataContext);
    const [showMFA, setShowMFA] = useAppSettings(APP_SETTINGS_KEYS.SHOW_REQUIRE_MFA_MESSAGE, {});
    const [showSSO, setShowSSO] = useAppSettings(APP_SETTINGS_KEYS.SHOW_REQUIRE_SSO_MESSAGE, { dismiss: false });

    const requireSsoSignIn = signInSettings?.requireSsoSignIn ?? false;
    const requireMfaForDirectUserSignIn = signInSettings?.requireMfaForDirectUserSignIn ?? false;

    // show banner only if role is admin/viewer
    const showForRole = orgUserRole === ROLE.ADMIN.key || orgUserRole === ROLE.VIEWER.key;

    const showForRemindMeLaterMFA =
        !showMFA.remindMeLaterDate || (showMFA.remindMeLaterDate && isCurrentDateAfterRemindMeLaterDate(showMFA.remindMeLaterDate));
    const showForRemindMeLaterSSO =
        !showSSO.dismiss &&
        (!showSSO.remindMeLaterDate || (showSSO.remindMeLaterDate && isCurrentDateAfterRemindMeLaterDate(showSSO.remindMeLaterDate)));
    // show MFA message if role is admin/viewer and no MFA and SSO are checked and users count > 0 and MFA remind me later not configured or configured and current date is after remind date
    const shouldShowRequireMFAMessage =
        showForRole && !requireSsoSignIn && !requireMfaForDirectUserSignIn && users.length > 0 && showForRemindMeLaterMFA;
    // show SSO message if role is admin/viewer and no SSO is checked and SSO remind me later not configured or configured and current date is after remind date
    const shouldShowRequireSSOMessage = showForRole && !requireSsoSignIn && showForRemindMeLaterSSO;

    const handleRemindMeLaterMFA = (e) => {
        e.preventDefault();
        const remindMeLaterDate = moment().add(7, "days").toISOString();
        setShowMFA((prev) => ({
            ...prev,
            remindMeLaterDate,
        }));
    };

    const handleDismissSSO = (e) => {
        e.preventDefault();
        setShowSSO({ dismiss: true });
    };

    const handleRemindMeLaterSSO = (e) => {
        e.preventDefault();
        const remindMeLaterDate = moment().add(7, "days").toISOString();
        setShowSSO((prev) => ({
            ...prev,
            remindMeLaterDate,
        }));
    };

    let message = "";
    if (shouldShowRequireMFAMessage) {
        // message - 'We care about your security, please enable MFA for non-SSO logins" (link) (Remind Me in a Week)';
        message = (
            <Row>
                <Col xs={0} sm={0} md={4} lg={4} xl={7} />
                <Col xs={17} sm={16} md={14} lg={16} xl={10} className="font-weight-normal" style={{ textAlign: "center" }}>
                    We care about your security,{" "}
                    <a href={USERS_PATH} className="font-weight-semibold" style={{ color: "white", textDecoration: "underline" }}>
                        {/* TODO Add link to users */}
                        Please enable MFA for non-SSO logins
                    </a>
                </Col>
                <Col xs={7} sm={8} md={6} lg={4} xl={7}>
                    <Row gutter={16} justify="end" className="mr-2">
                        <Col>
                            <a
                                href="#"
                                className="px-2 py-1"
                                onClick={handleRemindMeLaterMFA}
                                style={{ backgroundColor: "#ca8a05", color: "white", textDecoration: "none", borderRadius: "5px" }}>
                                Remind Me in a Week
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } else if (shouldShowRequireSSOMessage) {
        // Message - To enhance your account's security, you can configure login using SAML SSO only (link) (Remind Me in a Week) (Dismiss Forever)
        message = (
            <Row>
                <Col xs={0} sm={0} md={0} lg={2} xl={7} />
                <Col xs={17} sm={17} md={15} lg={14} xl={10} className="font-weight-normal" style={{ textAlign: "center" }}>
                    To enhance your account's security, you can configure{" "}
                    <a href={USERS_PATH} className="font-weight-semibold" style={{ color: "white", textDecoration: "underline" }}>
                        login using SAML SSO only
                    </a>
                </Col>
                <Col xs={7} sm={7} md={9} lg={8} xl={7}>
                    <Row gutter={16} justify="end">
                        <Col>
                            <a
                                href="#"
                                className="px-2 py-1"
                                onClick={handleRemindMeLaterSSO}
                                style={{ backgroundColor: "#ca8a05", color: "white", textDecoration: "none", borderRadius: "5px" }}>
                                Remind Me in a Week
                            </a>
                        </Col>
                        <Col className="mr-4">
                            <a href="#" onClick={handleDismissSSO} style={{ color: "white", textDecoration: "underline" }}>
                                Dismiss Forever
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    if (!message) {
        return null;
    }

    return (
        <div
            className="cm-banner w-100 font-weight-semibold bg-warningd"
            style={{
                height: "40px",
                marginBottom: "40px",
                position: "fixed",
                top: 0,
                zIndex: 101,
                padding: "10px",
                textAlign: "center",
                color: "white",
                background: "linear-gradient(to left, #fbbf24, #d97706)",
            }}>
            {message}
        </div>
    );
};

export default SignInSettingsBanner;
